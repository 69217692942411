<template>
  <div class="v-single-theme">
    <TheHeading level="h1">Tverrfaglige temaer</TheHeading>

    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <ContentRenderer :title="title" :content-items="contentItems" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onBeforeMount } from 'vue'

import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import ContentRenderer from '@/components/ContentRenderer'

export default {
  name: 'Home',

  components: {
    Breadcrumbs,
    TheHeading,
    ContentRenderer
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const content = computed(() => {
      return store.getters['content/getStructuresBySlugPath'](`tverrfaglige-temaer/${themeSlug}`, true)
    })
    const contentItems = computed(() => {
      return store.getters['content/getStructuresChildrens'](content.value.id, 'OBJECT')
    })
    const title = computed(() => {
      return content.value.name
    })
    const themeSlug = route.params.theme
    const breadcrumbs = computed(() => {
      return [
        {
          name: 'Tverrfaglige temaer',
          to: '/theme'
        },
        {
          name: store.getters['content/getStructuresBySlugPath'](`tverrfaglige-temaer/${content.value.slug}`).name
        }
      ]
    })

    onBeforeMount(async () => {
      await store.dispatch('content/setStructure', 'tverrfaglige-temaer')
      await store.dispatch('content/setStructure', `tverrfaglige-temaer/${themeSlug}`)
      await store.dispatch('content/setStructuresChildren', { slugPath: `tverrfaglige-temaer/${themeSlug}`, query: {limit: 100, filter: `{"type": "OBJECT"}`} })

      contentItems.value.forEach(item => {
        store.dispatch('content/setObject', item.contentId)
      })
    })

    return {
      content,
      breadcrumbs,
      title,
      contentItems
    }
  }
}
</script>
